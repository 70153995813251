import React, { useEffect, useState } from 'react';
import { Drawer } from 'vaul';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { Spinner } from 'react-bootstrap';
import { degrees, PDFDocument, rgb } from 'pdf-lib';
// import { jsPDF } from "jspdf";
import html2pdf from 'html2pdf.js';
import { addData, deleteData, getData } from '../Indexdb/indexedDB';
import myFunction from '../Helper';
import Pdfgenerator from '../Componets/Pdfgenerator';
// import { drawDOM, exportPDF } from '@progress/kendo-drawing';



const download_options = {
    selected: 1, // 1= watermark 2=logo
    watermark_text: 'Vidyadip - The Learning App',
    watermark_size: 40,
    watermark_darkness: 10,
    question: true,
    answer: true,
    solution: true,
    twocolumn: true,
    pyq: false,
    organization_text: 'Vidyadip Education',
    exam_date: new Date().toISOString().split('T')[0],
    exam_time: 0,
    exam_name: 'Vidyadip',
    print_type: true,

}

function Downloadpdf({ callbackdata, data, removeindexdata }) {
    const [selected_questions_drawer, set_selected_questions_drawer] = useState(false)
    const [setting_drawer, set_setting_drawer] = useState(false)
    const [download_option, set_download_option] = useState(download_options)
    const [download_loader, set_download_loader] = useState(false)
    const [pdf_loader, set_pdf_loader] = useState(false)
    const [pdf_loader_text, set_pdf_loader_text] = useState('')
    const [contentref, set_contentref] = useState('')
    const [select_data, set_select_data] = useState([])


    useEffect(() => {
        Gettingselectdata()
    }, [data])


    useEffect(() => {
        setTimeout(() => {
            // window.MathJax.typesetPromise(); // Trigger MathJax to render LaTeX expressions
            myFunction.reloadMathjax();
        }, 50);
    }, [selected_questions_drawer]);

    const [renderedHtml, setRenderedHtml] = useState([]);

    const groupByTopicId = (select_data) => {
        return select_data.reduce((acc, current) => {
            (acc[current.topic_id] = acc[current.topic_id] || []).push(current);
            return acc;
        }, {});
    };

    useEffect(() => {
        const groupedData = groupByTopicId(select_data);
        const content = [];

        Object.keys(groupedData).forEach((topicId, idx) => {
            content.push(Writinghtml(groupedData, topicId, idx));
        });

        setRenderedHtml(content);
    }, [select_data]);

    function createMarkup(data) {
        return { __html: data };
    }


    const Writinghtml = (groupedData, topicId, idx) => {
        const title = `Q-${idx + 1} ` + groupedData[topicId][0]?.title;

        const questions = groupedData[topicId]
            .sort((a, b) => a.index_id - b.index_id) // Sort by index_id
            .map((question, index) => (
                <li key={index}>
                    <div className='question-main-box pdf-questions not-selectable'>
                        <div className='cancel-btn-top' onClick={() => removeindex(question.id)}>
                            <svg viewBox="0 0 24 24" fill="none" width={25} height={25}>
                                <path d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z" stroke="#F05A5A" strokeWidth="2"></path>
                                <path d="M9 9L15 15M15 9L9 15" stroke="#F05A5A" strokeWidth="2" strokeLinecap="round"></path>
                            </svg>
                        </div>
                        <>
                            <h1 dangerouslySetInnerHTML={createMarkup(
                                `<span class='pe-1'>(${index + 1})</span>${question.question}${download_option.pyq && question.remark && question.remark.trim() !== '' ? ` (${question.remark})` : ''}`
                            )}></h1>
                        </>
                    </div>
                </li>
            ));

        return (
            <div key={idx}>
                <h1 style={{ fontSize: '15px', fontWeight: '600', margin: '20px 0' }} dangerouslySetInnerHTML={createMarkup(title)}></h1>
                <ul className='question-list'>
                    {questions}
                </ul>
            </div>
        );
    };

    async function Gettingselectdata(params) {
        const temp = await getData('selection')
        set_select_data(temp ?? [])

        if (temp && temp.length == 0) set_selected_questions_drawer(false)
        if (callbackdata) callbackdata(temp ?? [])
    }

    const removeindex = async (id) => {
        await deleteData(id, 'selection')
        Gettingselectdata()
        removeindexdata()

    }

    const Generatepdf = (contentRef) => {
        const content = contentRef.current;
        set_contentref(content)
    }


    const Generatepdftohtml = async () => {
        try {
            set_download_loader(true)
            const content = contentref;

            // Make content visible for rendering
            content.classList.remove('d-none');

            // Wait for images to load and convert them to base64
            const images = Array.from(content.getElementsByTagName('img'));
            await Promise.all(images.map(async (img) => {
                try {
                    if (img.src) {
                        const base64Src = await imageToBase64(img);
                        img.src = base64Src;
                    }
                } catch (error) {
                    console.error(`Error converting image to base64: ${error.message}`);
                    // If an error occurs, remove the image element from the DOM
                    img.parentNode.removeChild(img);
                }
            }));

            images.forEach(img => {
                img.style.maxWidth = '100%';
                img.style.height = 'auto';
                if (download_option.print_type) {
                    img.style.filter = 'grayscale(1) contrast(1.5) brightness(1)';
                    img.style.mixBlendMode = 'multiply';
                    // You can apply any other styles you need for the print version here
                }
            });

            // Page dimensions setup
            const pageHeightMM = 297; // A4 height in mm
            const pageWidthMM = 210; // A4 width in mm
            const marginMM = 3;
            const paddingMM = 3;
            const pxToMm = 0.264583;
            const mmToPx = 1 / pxToMm;


            const marginPx = marginMM * mmToPx;
            const paddingPx = paddingMM * mmToPx;
            const pageHeightPx = (pageHeightMM * mmToPx) - (2 * marginPx);
            const pageWidthPx = (pageWidthMM * mmToPx) - (2 * marginPx);

            const mcqsListItems = content.querySelectorAll('.mcqs');

            // Define the preferred and maximum width percentages
            const preferredWidth = 50; // Preferred width as a percentage (50% in this case)
            const maxWidth = download_option.twocolumn ? pageWidthPx / 2 : pageWidthPx; // Maximum width as a percentage (100%)

            // Function to calculate the clamp value dynamically
            function getClampValue(minWidth, preferredWidth, maxWidth) {
                return `clamp(${minWidth}px, ${preferredWidth}%, ${maxWidth}px)`;
            }

            // // Iterate through each ul.mcqs to apply dynamic min-width for each li in the ul
            mcqsListItems.forEach(ul => {
                const liItems = ul.querySelectorAll('li'); // Get all li elements inside .mcqs

                // Calculate the max width among all li elements in this ul
                let maxLiWidth = [];

                liItems.forEach(li => {
                    const liWidth = li.scrollWidth; // Get the width of each li element
                    maxLiWidth.push(liWidth)

                });
                const maxValue = Math.max(...maxLiWidth);

                //Apply the calculated min-width using the clamp function for each li element
                liItems.forEach(li => {
                    li.style.width = getClampValue(maxValue, preferredWidth, maxWidth);
                });
            });

            // Ensure content is fully rendered
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Main header height if it exists
            const mainHeader = content.querySelector('.main-header');
            let mainHeaderHeightPx = 0;
            let fix_height = 0;
            if (mainHeader) {
                mainHeaderHeightPx = mainHeader.offsetHeight;
                fix_height = mainHeader.offsetHeight;
            }

            // Initial page setup for PDF without solutions
            const pages = [];

            let currentPage = await createPage(pageWidthPx, mainHeaderHeightPx, pageHeightPx, (2 * marginPx));
            pages.push(currentPage);
            document.body.appendChild(currentPage.container);
            if (download_option.question) {
                // Insert main header for the first part (without solutions)
                if (mainHeader) {
                    const headerClone = mainHeader.cloneNode(true);
                    headerClone.style.width = '100%';
                    headerClone.style.borderBottom = `1px solid ${download_option.print_type ? '#000' : '#495eca'}`;  // Or any other style you want
                    currentPage.container.insertAdjacentElement('afterbegin', headerClone);
                    const contentArea = currentPage.container.querySelector('.pdf-content');
                    if (contentArea) {
                        contentArea.style.height = (pageHeightPx - (fix_height + 30)) + 'px';
                    }
                }

                // Allow time for rendering
                await new Promise(resolve => requestAnimationFrame(resolve));

                // Clone content and remove the solutions
                const clonedContentWithoutSolutions = content.cloneNode(true);
                const solutionDivs = clonedContentWithoutSolutions.querySelectorAll('.solution-div');
                solutionDivs.forEach(div => div.remove()); // Remove all solution-divs

                const answerkey = clonedContentWithoutSolutions.querySelectorAll('.answer-key');
                answerkey.forEach(div => div.remove()); // Remove all solution-divs

                // Distribute content without solutions into the PDF
                await distributeContent(clonedContentWithoutSolutions, currentPage, pages, pageHeightPx, pageWidthPx, marginPx, mainHeaderHeightPx);
            }


            // Now we will handle the content WITH the solutions
            // if (download_option.solution) {
            //     currentPage = await createPage(pageWidthPx, mainHeaderHeightPx, pageHeightPx);
            //     pages.push(currentPage);
            //     document.body.appendChild(currentPage.container);

            //     // Insert main header again for the solution part only if download_option.solution is true
            //     if (mainHeader) {
            //         const headerCloneForSolutions = mainHeader.cloneNode(true);
            //         headerCloneForSolutions.style.width = '100%';
            //         currentPage.container.insertAdjacentElement('afterbegin', headerCloneForSolutions);
            //         const contentArea = currentPage.container.querySelector('.pdf-content');
            //         if (contentArea) {
            //             contentArea.style.paddingTop = 15 + 'px';
            //             contentArea.style.height = (pageHeightPx - (fix_height - 15)) + 'px';
            //         }
            //     }

            //     // Distribute original content with solutions into the PDF
            //     await distributeContent(content, currentPage, pages, pageHeightPx, pageWidthPx, marginPx, mainHeaderHeightPx);
            // }
            if (download_option.answer) {
                const answerKeyDiv = document.querySelector('.answer-key');
                if (answerKeyDiv) {

                    // Create a new page for answers
                    const container = document.createElement('div');
                    container.className = 'pdf-content-container';

                    const contentArea = document.createElement('div');
                    contentArea.style.display = 'flex';
                    contentArea.className = 'pdf-content';
                    contentArea.style.flexDirection = 'row';
                    contentArea.style.columnGap = '10px';

                    const contentAreaHeight = pageHeightPx - mainHeaderHeightPx - 30;
                    contentArea.style.height = contentAreaHeight + 'px'; // Set height dynamically

                    const leftColumn = document.createElement('div');
                    leftColumn.className = 'left';
                    leftColumn.style.flex = '1';
                    leftColumn.style.boxSizing = 'border-box';
                    leftColumn.style.paddingTop = '10px';
                    leftColumn.style.width = (pageWidthPx + 'px');

                    // const rightColumn = document.createElement('div'); // Assuming you also want a right column
                    // rightColumn.className = 'right';
                    // rightColumn.style.flex = '1';
                    // rightColumn.style.boxSizing = 'border-box';
                    // rightColumn.style.paddingTop = '10px';
                    // rightColumn.style.width = (pageWidthPx + 'px');

                    contentArea.appendChild(leftColumn);
                    // contentArea.appendChild(rightColumn);
                    container.appendChild(contentArea); // Append contentArea to container
                    pages.push({ container, leftColumn }); // Store structured object
                    document.body.appendChild(container); // Append container to the document body

                    // Insert main header for the answers part
                    if (mainHeader) {
                        const headerCloneForAnswers = mainHeader.cloneNode(true);
                        headerCloneForAnswers.style.width = '100%'; // Set width to full
                        headerCloneForAnswers.style.borderBottom = `1px solid ${download_option.print_type ? '#000' : '#495eca'}`;

                        const examName = headerCloneForAnswers.querySelector('.exam-name');
                        if (examName) {
                            examName.innerHTML = download_option.exam_name; // Clear existing text
                            examName.innerHTML += `<br /> (Answer Key)`; // Insert new text
                        }

                        container.insertAdjacentElement('afterbegin', headerCloneForAnswers); // Insert header at the beginning of container
                    }

                    // Ensure .answer-key div takes full width and add to left column
                    const answerKeyDiv = document.querySelector('.answer-key');
                    if (answerKeyDiv) {
                        answerKeyDiv.style.width = '100%'; // Set width to full
                        answerKeyDiv.style.boxSizing = 'border-box';
                        answerKeyDiv.style.padding = '10px';

                        // Clone the answerKeyDiv to append it to left column
                        leftColumn.appendChild(answerKeyDiv.cloneNode(true)); // Use cloneNode to keep the original in place
                    } else {
                        console.error('.answer-key element not found'); // Log error if the element is not found
                    }

                    // Check if content area has height before proceeding
                    const contentHeight = container.scrollHeight; // Get the scroll height of the container
                    console.log('Current Container Height for Answers:', contentHeight); // Debugging line

                    if (contentHeight < 1) {
                        console.error('Content height for answers is invalid:', contentHeight);
                    }
                }
            }


            // Now we will handle the content WITH the solutions
            if (download_option.solution) {
                // Create a new page for solutions
                currentPage = await createPage(pageWidthPx, mainHeaderHeightPx, pageHeightPx - 10, (2 * marginPx));
                pages.push(currentPage);
                document.body.appendChild(currentPage.container);

                // Insert main header for the solutions part
                if (mainHeader) {
                    const headerCloneForSolutions = mainHeader.cloneNode(true);
                    headerCloneForSolutions.style.width = '100%';
                    headerCloneForSolutions.style.borderBottom = `1px solid ${download_option.print_type ? '#000' : '#495eca'}`;  // Or any other style you want

                    // Find the .exam-name element and update the text
                    const examName = headerCloneForSolutions.querySelector('.exam-name');
                    if (examName) {
                        // Remove existing text
                        examName.innerHTML = download_option.exam_name; // Clear existing text
                        // Add new text with "(Solution)"
                        examName.innerHTML += `<br /> (Solution)`; // Insert new text
                    }


                    currentPage.container.insertAdjacentElement('afterbegin', headerCloneForSolutions);
                    const contentArea = currentPage.container.querySelector('.pdf-content');
                    if (contentArea) {
                        contentArea.style.height = (pageHeightPx - (fix_height + 30)) + 'px';
                        console.log('Content Area Height for Solutions:', contentArea.style.height); // Debugging line
                    }
                }

                // Wait for any potential delayed rendering (especially for images)
                await new Promise(resolve => setTimeout(resolve, 1000));


                const clonedContentWithoutSolutions = content.cloneNode(true);
                const answerkey = clonedContentWithoutSolutions.querySelectorAll('.answer-key');
                answerkey.forEach(div => div.remove()); // Remove all solution-divs

                // Check if content area has height before distributing
                const contentHeight = currentPage.container.scrollHeight; // Get the scroll height of the current page's content
                console.log('Current Page Height for Solutions:', contentHeight); // Debugging line

                // If the content area is too small or not calculated, log an error
                if (contentHeight < 1) {
                    console.error('Content height for solutions is invalid:', contentHeight);
                } else {
                    // Distribute original content with solutions into the PDF
                    await distributeContent(clonedContentWithoutSolutions, currentPage, pages, pageHeightPx, pageWidthPx, marginPx, mainHeaderHeightPx);
                }
            }

            const opt = {
                margin: [marginMM, marginMM, marginMM, marginMM],
                filename: 'Invoice.pdf',
                image: { type: 'jpeg', quality: 1 },
                // html2canvas: { dpi: 600, scale: 5, useCORS: true, letterRendering: true, preferCanvas: true, },
                html2canvas: {
                    dpi: 800,
                    letterRendering: true,
                    useCORS: true,
                    margin: 1,
                    scale: 6,
                    allowTaint: true,
                    imageTimeout: 3000,
                    preferCanvas: true, // !!! Don't forget about this option if you are using  leaflet and render maps 
                    scrollY: 0
                },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', compress: true },
                pagebreak: { mode: ['css', 'legacy'], after: '#pagebreack' }
            };




            // Create final container for all pages
            const final = document.createElement('div');
            final.style.fontFamily = 'Anek Gujarati, sans-serif';
            final.style.padding = `${paddingPx}px 0px`;
            final.classList = `${download_option.print_type ? 'black-white' : ''}`;

            pages.forEach((page, index) => {
                if (page && page.container) { // Ensure page and container are defined
                    if (index !== pages.length - 1) {
                        page.container.classList += ' pagebreak';
                    }
                    // page.container.style.border = '1px solid #495eca'; // Ensure we access the container's style
                    // page.container.style.padding = '5px'; // Ensure we access the container's style
                    final.appendChild(page.container);
                } else {
                    console.error(`Page or container is undefined at index ${index}`);
                }
            });
            if (final.children.length === 0) {
                console.error("No valid containers to draw.");
                return; // Exit if there are no valid containers
            }

            // document.body.appendChild(final);

            // drawDOM(final, {
            //     paperSize: 'A4',
            //     margin: { top: '1cm', bottom: '1cm' },
            //     scale: 0.8,
            // })
            //     .then((group) => {
            //         return exportPDF(group, {
            //             paperSize: 'A4',
            //             margin: { top: '1cm', bottom: '1cm' },
            //         });
            //     })
            //     .then((data) => {
            //         const link = document.createElement('a');
            //         link.href = data;
            //         link.download = 'table-sample.pdf'; // Custom name for the PDF
            //         link.click();
            //     })
            //     .catch((error) => {
            //         console.error("Error generating PDF:", error);
            //     })
            //     .finally(() => {
            //         // Clean up
            //         document.body.removeChild(final);
            //     });

            // Append the final container to the body temporarily


            // Now draw the DOM and generate the PDF


            // console.log(final)
            // const doc = new jsPDF();
            // doc.html(final, {
            //     callback: (doc) => {
            //         doc.save('my-document.pdf');
            //     },
            //     x: 10,
            //     y: 10,
            //     width: 100, // Adjust width to fit content
            //     windowWidth: 900
            // });

            console.log(final);
            const styles = `
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet" />
            <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Anek+Gujarati:wght@100..800&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap" rel="stylesheet">

            <style>
            body {
  margin: 0;
  padding: 0;
  font-family: "Anek Gujarati", sans-serif;
  font-size: 14px;
  width: 100%;
  background: #fff;
  overflow-x: hidden;
  user-select: none;
}
            .download-drawer{
  padding-bottom: 15px;
}
.not-selectable {
  user-select: none;
}
.pdf-content-container h1 {
  padding: 0;
  color: #495eca;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}
.pdf-content-container h1 span {
  color: #495eca;
  font-weight: 600;
}
.black-white .pdf-content-container h1,.black-white .pdf-content-container .pdf-content h1 span{
  color: #000 !important;
}


.pdf-content-container bdi,
.pdf-content-container ul li {
  background-color: transparent;
  height: auto;
  width: auto;
  padding: 0;
  font-size: 14px;
  color: var(--black-color);
  font-weight: 400;
  margin-right: 5px;
  margin-top: 0 !important;
  list-style-type: none;
}

.pdf-content-container ul {
  padding-left: 10px;
  padding-bottom: 8px;
  margin-bottom: 0;
  /* justify-content: space-between;
  display: flex;
  flex-wrap: wrap; */
}
            .generate-pdf {
  bottom: 10px;
}
#pagebreack {
  page-break-after: always;
}
.pdf-content-container {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: keep-all;
  word-break: keep-all;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  width: 750px;
  margin: 0 auto;
  /* height: 1099px; */
}
.main-header h1 {
  font-size: 30px;
  font-weight: 600;
}
.main-header ul {
  padding: 0;
}
.main-header {
  /* border-bottom: 1px solid rgb(73, 94, 202); */
  padding-bottom: 10px;
}

            .pdf-content-container ul.mcqs li {
  display: inline-block;
  margin-right: 0;
}
.pdf-content-container ul.mcqs {
  display: flex;
  flex-wrap: wrap;
}
.cust-spin {
  width: 23px;
  height: 23px;
}
.pdf-content-container .solution-div p,
.pdf-content-container h1 p {
  margin: 0;
}
.answer-key ul {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #495eca;
}

.answer-key ul li {
  margin: 0;
  padding: 5px;
  border-right: 1px solid #495eca;
}
.black-white .answer-key ul,.answer-key ul li{
  border-color: #000 !important;
}
.black-white .pdf-content-container .pdf-content img {
  filter: grayscale(1) contrast(1.5) brightness(1) !important;
   mix-blend-mode: multiply !important;
}
            </style>`
            // Open a new tab
            // const newTab = window.open('', '_blank');

            // // Set HTML content in the new tab if it successfully opened
            // if (newTab) {
            //     newTab.document.head.innerHTML = styles; // Use outerHTML to get the HTML string of the element
            //     newTab.document.body.innerHTML = final.outerHTML; // Use outerHTML to get the HTML string of the element
            // } else {
            //     console.error("Failed to open new tab. Please check your browser's popup settings.");
            // }
            const finalHTMLString = `${styles}${final.outerHTML}`;
            try {
                (window["ReactNativeWebView"] || window).parent.postMessage({
                    type: 'html',
                    message: finalHTMLString // The HTML string you want to send
                }, '*');
            } catch (e) {
                console.error("ReactNativeWebView object not found." + e);
            }



            function _savePdf(blob, filename) {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            async function _mergePDFs(pdfs) {
                const mergedPdf = await PDFDocument.create();

                // Loop through each PDF blob and merge the pages
                for (const pdfBlob of pdfs) {
                    const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
                    const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                    copiedPages.forEach((page) => mergedPdf.addPage(page));
                }

                // Add watermark and border to each page
                const pages = mergedPdf.getPages();
                const borderWidth = 0.5; // Set border thickness
                const borderColor = rgb(0, 0, 0); // Set border color (black or adjust as needed)

                // Check if a watermark is provided
                if (download_option.watermark_text.trim() !== '') {
                    const fontSize = download_option.watermark_size; // Set watermark font size
                    const opacity = download_option.watermark_darkness / 100; // Set watermark opacity
                    const textColor = rgb(0, 0, 0); // Set watermark text color (black)

                    pages.forEach((page) => {
                        const { width, height } = page.getSize();
                        console.log(width, height)
                        const watermarkText = download_option.watermark_text;

                        // Calculate watermark position (centered)
                        const textWidth = fontSize * watermarkText.length * 0.3;
                        const x = (width - textWidth) / 2;
                        const y = ((height + 50) - fontSize) / 2;

                        // Draw watermark
                        page.drawText(watermarkText, {
                            x: x,
                            y: y,
                            size: fontSize,
                            rotate: degrees(45),
                            opacity: opacity,
                            color: textColor,
                        });
                    });
                }
                // Optimize PDF by removing unused resources
                mergedPdf.setProducer('Optimized');
                mergedPdf.setCreator('Custom PDF Generator');
                // Save the merged PDF with watermark and border
                const mergedPdfBytes = await mergedPdf.save({ useObjectStreams: true });
                const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
                return mergedPdfBlob;
            }

            async function appendPagesAsync(pages) {
                const PDFs = [];
                const batchSize = 5; // Set the batch size to 5
                set_pdf_loader(true);

                for (const [index, page] of pages.entries()) {
                    const final = document.createElement('div');
                    final.style.fontFamily = 'Anek Gujarati, sans-serif';
                    final.style.padding = `${paddingPx}px`;
                    final.style.border = `0.5px solid ${download_option.print_type ? '#000' : '#495eca'}`;
                    final.classList = `${download_option.print_type ? 'black-white' : ''}`;

                    // Page break for all but the last page
                    if (index !== pages.length - 1) {
                        page.container.id = 'pagebreak';
                    }

                    final.appendChild(page.container);
                    console.log(final, "____");
                    set_pdf_loader_text(`${index} of ${pages.length}`);

                    // Generate a PDF Blob for the current page
                    const pdfBlob = await html2pdf().set(opt).from(final).outputPdf('blob');

                    // Check if the generated PDF is not blank
                    const pdfDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
                    if (pdfDoc.getPageCount() > 0) { // Ensure it's not a blank page
                        PDFs.push(pdfBlob); // Store the generated PDF blob
                    } else {
                        console.log(`Skipped blank page at index ${index}`);
                    }
                }

                const mergedPdf = await _mergePDFs(PDFs);
                set_pdf_loader(false);
                _savePdf(mergedPdf, `${download_option.exam_name}-${download_option.exam_date}.pdf`);
            }


            // Call the function and await its completion
            await appendPagesAsync(pages);

            // Set up an event listener for after the print dialog closes






            // PDF generation options


            // Generate the PDF
            // const pdf = await html2pdf().set(opt).from(final).toPdf().get('pdf');



            // const totalPages = pdf.internal.getNumberOfPages();
            // const pageWidth = pdf.internal.pageSize.getWidth();
            // const pageHeight = pdf.internal.pageSize.getHeight();

            // const borderThickness = 0.2;

            // // Add watermark and border to each page
            // for (let i = 1; i <= totalPages; i++) {
            //     pdf.setPage(i);

            //     const pageIndex = i - 1; // Adjust for zero-based index
            //     const mainHeaderExists = pages[pageIndex] && pages[pageIndex].container.querySelector('.main-header');

            //     if (mainHeaderExists) {
            //         const mainHeaderHeight = fix_height + marginPx + 15; // Get height of the header
            //         const mainHeaderHeightMM = mainHeaderHeight * pxToMm; // Convert to mm
            //         const headerBottomLineY = mainHeaderHeightMM; // Y position for the bottom line

            //         // Draw the bottom line
            //         pdf.setLineWidth(borderThickness);
            //         pdf.setDrawColor(73, 94, 202); // Set the line color
            //         pdf.line(marginMM, headerBottomLineY, pageWidth - marginMM, headerBottomLineY); // Draw the line
            //     }

            //     if (download_option.watermark_text.trim() !== '') {
            //         pdf.setTextColor(0, 0, 0);
            //         pdf.setFontSize(download_option.watermark_size);
            //         pdf.setGState(new pdf.GState({ opacity: download_option.watermark_darkness / 100 }));
            //         pdf.text(download_option.watermark_text, pageWidth / 2, pageHeight / 2, { angle: 45, align: 'center' });
            //         pdf.setGState(new pdf.GState({ opacity: 1 }));
            //     }


            //     // Draw the border
            //     pdf.setLineWidth(borderThickness);
            //     pdf.setDrawColor(73, 94, 202);
            //     pdf.rect(marginMM, marginMM, pageWidth - 2 * marginMM, pageHeight - 2 * marginMM);

            //     // Two-column layout separator
            //     if (download_option.twocolumn) {
            //         let startY = marginMM;
            //         const pageIndex = i - 1;
            //         if (pages[pageIndex] && pages[pageIndex].container.querySelector('.main-header')) {
            //             const mainHeaderElement = pages[pageIndex].container.querySelector('.main-header');
            //             const mainHeaderHeight = mainHeaderElement ? fix_height + 15 : 0;
            //             const mainHeaderHeightMM = mainHeaderHeight * pxToMm;
            //             startY += mainHeaderHeightMM;
            //         }
            //         pdf.setLineWidth(borderThickness);
            //         pdf.line(pageWidth / 2, startY, pageWidth / 2, pageHeight - marginMM);
            //     }
            // }

            // // Save the PDF
            // await pdf.save('Invoice_with_Watermark_and_Border.pdf');

            mcqsListItems.forEach(ul => {
                const liItems = ul.querySelectorAll('li'); // Get all li elements inside .mcqs

                //Apply the calculated min-width using the clamp function for each li element
                liItems.forEach(li => {
                    li.style.width = 'auto';
                });
            });

            // Hide the content after rendering
            content.classList.add('d-none');
            content.style.columnGap = '0px';
            set_download_loader(false)


        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };


    const distributeContent = async (
        content,
        currentPage,
        pages,
        pageHeightPx,
        pageWidthPx,
        marginPx,
        mainHeaderHeightPx
    ) => {
        let currentColumn = currentPage.leftColumn;
        let currentColumnHeight = 25;
        const nodes = Array.from(content.childNodes);
        console.log(nodes);
        let nodeIndex = 0;
        const batchSize = 20;

        // Use a Promise to handle completion of all batches
        const processBatches = () => {
            return new Promise((resolve) => {
                const processBatch = async () => {
                    let processedCount = 0;

                    for (let i = 0; i < batchSize && nodeIndex < nodes.length; i++, nodeIndex++) {
                        const node = nodes[nodeIndex];

                        // Skip non-element nodes and main-header
                        if (node.nodeType !== Node.ELEMENT_NODE || (node.classList && node.classList.contains('main-header'))) {
                            continue;
                        }

                        const clone = node.cloneNode(true);

                        try {
                            console.log(`Before height calculation for node ${nodeIndex + 1}`);
                            let cloneHeight = await getContentHeight(clone, pageWidthPx);
                            console.log(`After height calculation for node ${nodeIndex + 1} - Clone Height: ${cloneHeight}`);

                            // Check if current column has space for the content
                            if ((currentColumnHeight + cloneHeight) > (1100 - (mainHeaderHeightPx + 50))) {
                                if ((currentColumn === currentPage.leftColumn) && download_option.twocolumn) {
                                    currentColumn = currentPage.rightColumn;
                                    currentColumnHeight = 50; // Reset for right column
                                } else {
                                    // Create new page
                                    currentPage = await createPage(pageWidthPx, mainHeaderHeightPx, pageHeightPx, (2 * marginPx));
                                    pages.push(currentPage);
                                    document.body.appendChild(currentPage.container);

                                    currentColumn = currentPage.leftColumn;
                                    currentColumnHeight = 25; // Reset height for new page
                                    mainHeaderHeightPx = 0; // Reset header height for new page
                                }
                            }

                            // Append the clone to the current column
                            currentColumn.appendChild(clone);
                            currentColumnHeight += cloneHeight;
                            console.log(`Node appended. Current Column Height: ${currentColumnHeight}`);

                            processedCount++;
                        } catch (error) {
                            console.error(`Error processing node ${nodeIndex + 1}:`, error);
                        }

                        // Throttle to avoid blocking
                        if (processedCount >= batchSize) {
                            await new Promise((resolve) => setTimeout(resolve, 500)); // Slight delay for throttling
                        }
                    }

                    // Check if more nodes remain to process
                    if (nodeIndex < nodes.length) {
                        requestAnimationFrame(processBatch); // Continue processing
                    } else {
                        console.log('All nodes processed.');
                        resolve(); // Resolve the Promise when all nodes are processed
                    }
                };

                // Start processing the first batch
                processBatch();
            });
        };

        // Wait for all batches to complete
        await processBatches();
        console.log('Distribution of content completed.');
    };


    // Helper function to create a new page with two columns if enabled
    async function createPage(pageWidthPx, mainHeaderHeightPx, pageHeightPx) {

        const container = document.createElement('div');
        container.className = 'pdf-content-container';

        const contentArea = document.createElement('div');
        contentArea.style.display = 'flex';
        contentArea.className = 'pdf-content';
        contentArea.style.flexDirection = 'row';
        contentArea.style.columnGap = '10px';

        // Determine the height of the content area based on the presence of the main-header
        // const marginPx = 3; // Adjust as per your layout
        const contentAreaHeight = pageHeightPx - 25;
        contentArea.style.height = contentAreaHeight + 'px'; // Set height dynamically

        const leftColumn = document.createElement('div');
        leftColumn.className = 'left';
        leftColumn.style.flex = '1';
        leftColumn.style.boxSizing = 'border-box';
        leftColumn.style.paddingTop = '10px';

        leftColumn.style.width = (download_option.twocolumn) ? (770 / 2 + 'px') : (770 + 'px');

        const rightColumn = document.createElement('div');
        rightColumn.className = 'right';
        rightColumn.style.flex = (download_option.twocolumn) ? '1' : '0';
        rightColumn.style.boxSizing = 'border-box';
        rightColumn.style.paddingTop = '10px';

        if (download_option.twocolumn) rightColumn.style.borderLeft = `1px solid ${download_option.print_type ? '#000' : '#495eca'} `;
        if (download_option.twocolumn) rightColumn.style.paddingLeft = '10px';


        rightColumn.style.width = download_option.twocolumn ? (770 / 2 + 'px') : '0';

        contentArea.appendChild(leftColumn);
        if (download_option.twocolumn) {
            contentArea.appendChild(rightColumn);
        }

        container.appendChild(contentArea);
        return { container, leftColumn, rightColumn };
    }

    // Helper function to get the content height including images
    async function getContentHeight(element, pageWidthPx) {
        return new Promise(async (resolve) => {
            try {
                // Create a container to measure the content's height
                const container = document.createElement('div');
                container.style.position = 'absolute';  // Ensure the container does not affect layout
                container.style.left = '-9999px';      // Place it off-screen to avoid visual impact
                container.style.width = download_option.twocolumn ? `${pageWidthPx / 2}px` : `${pageWidthPx}px`; // Handle two-column layout if enabled
                container.style.boxSizing = 'border-box'; // Ensure padding and borders are included in width calculation
                container.className = 'pdf-content-container';
                container.style.display = 'flex';
                container.style.columnGap = '10px';
                download_option.twocolumn && (container.style.paddingLeft = '10px')

                // Clone the original element and append it to the container
                const clonedElement = element.cloneNode(true);
                container.appendChild(clonedElement);

                // Append the container to the body for rendering
                document.body.appendChild(container);

                // Wait for all images in the cloned content to fully load
                const images = Array.from(container.getElementsByTagName('img'));
                const imagePromises = images.map(img => new Promise((resolve) => {
                    const tempImg = new Image();
                    tempImg.onload = () => resolve(tempImg.height);
                    tempImg.onerror = () => {
                        console.error(`Error loading image: ${img.src}`);
                        resolve(0); // Resolve with height 0 on error
                    };
                    tempImg.src = img.src;  // Trigger the image load
                }));

                // After all images have loaded
                Promise.all(imagePromises).then(() => {
                    // Calculate the total height after all images are loaded
                    const totalHeight = container.scrollHeight;

                    // Remove the container from the DOM after measuring
                    document.body.removeChild(container);

                    // Return the calculated height
                    resolve(totalHeight);
                }).catch(err => {
                    console.error('Error resolving image promises:', err);
                    // Clean up the container
                    document.body.removeChild(container);
                    resolve(0); // Resolve with height 0 in case of an error
                });
            } catch (error) {
                console.error('Error in getContentHeight:', error);
                resolve(0); // Resolve with height 0 in case of an error
            }
        });
    }

    function imageToBase64(img) {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const imgElement = new Image();

            imgElement.crossOrigin = 'Anonymous'; // Set CORS to Anonymous

            imgElement.onload = () => {
                canvas.width = imgElement.width;
                canvas.height = imgElement.height;
                ctx.drawImage(imgElement, 0, 0);
                const base64Data = canvas.toDataURL(imgElement.src.split(';')[0].split(':')[1]);
                resolve(base64Data);
            };

            imgElement.onerror = reject;
            let originalUrl = img.src;
            let url = new URL(originalUrl);
            if (url.hostname.includes('vidyadip')) {
                url.hostname = 'diwufdefd1x6l.cloudfront.net';
                let updatedUrl = url.toString();
                imgElement.src = updatedUrl;
            } else {
                imgElement.src = originalUrl;
            }
        });
    }


    const change_option = (val, name) => {
        const temp = { ...download_option, [name]: val }
        set_download_option(temp)
    }

    const handleChange = (values, name) => {
        const temp = { ...download_option, [name]: values[1] }
        set_download_option(temp)
    };

    return (
        <>

            <div className='row py-3 sticky-bottom  top-0 bg-white z-0  border-top pagination pdf-btn-sticky'>
                <div className='col-md-12'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div onClick={() => set_selected_questions_drawer(true)}>
                            <p className='mb-0'>Edit Questions</p>
                        </div>
                        <div onClick={() => set_setting_drawer(true)}>
                            <button className='btn btn-comn-1 export-btn'>Export Pdf</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Setting drawer */}
            <Drawer.Root
                shouldScaleBackground
                open={setting_drawer}
                // dismissible
                // modal
                onClose={() => {
                    set_setting_drawer(false)
                    set_download_option(download_options)
                }}
            >
                <Drawer.Portal>
                    <div onClick={() => {
                        set_setting_drawer(false)
                        set_download_option(download_options)

                    }}>
                        <Drawer.Overlay className='cust-overlay-drawer' />
                    </div>
                    <Drawer.Content>
                        <div className='drawer-inner pt-2'>
                            <div className='row'>
                                <div className='col-md-12 d-flex justify-content-center'>
                                    <div className='border-top-notch'>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <h3>Download Options</h3>
                                </div>
                                <div className='col-md-12'>
                                    <div className='row scrollable-drawer  solutions-drw download-drawer' style={{ maxHeight: '430px' }}>
                                        <div className='col-md-12 mb-3'>
                                            <section className="row mt-2">
                                                <div className="col-md-12">
                                                    <div className="cust-tab-sem">
                                                        <ul>
                                                            <li>
                                                                <input
                                                                    type='radio'
                                                                    defaultChecked={download_option.selected == 1}
                                                                    // onChange={(e) => { change_option(e) }}
                                                                    value={1}
                                                                    name='selected'
                                                                    className='d-none'
                                                                    id='watermark'
                                                                />
                                                                <label htmlFor={'watermark'} className='cursor-pointer'>
                                                                    Watermark
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </section>
                                            {download_option.selected == 1 &&
                                                <>
                                                    <section className="row mt-4">
                                                        <div className="col-md-12 mb-3">
                                                            <label className='cust-label'>Watermark Text</label>
                                                            <input type='text' className='form-control cust-form-control' defaultValue={download_option.watermark_text} name='watermark_text' onChange={(e) => { change_option(e.target.value, 'watermark_text') }} />
                                                            <small>(Allowed character : A-Z a-z 0-9 ())</small>
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label className='cust-label'>Watermark size</label>
                                                            <RangeSlider
                                                                className="single-thumb mb-2"
                                                                min={40}
                                                                max={100}
                                                                defaultValue={[0, download_option.watermark_size]}
                                                                thumbsDisabled={[true, false]}
                                                                rangeSlideDisabled={true}
                                                                onInput={(e) => handleChange(e, 'watermark_size')}
                                                            />
                                                            <small className='mt-5'>Selected watermarksize: {download_option.watermark_size}</small>

                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <label className='cust-label'>Watermark  darkness</label>
                                                            <RangeSlider
                                                                className="single-thumb mb-2"
                                                                min={10}
                                                                max={100}
                                                                defaultValue={[0, download_option.watermark_darkness]}
                                                                thumbsDisabled={[true, false]}
                                                                rangeSlideDisabled={true}
                                                                onInput={(e) => handleChange(e, 'watermark_darkness')}
                                                            />
                                                            <small>Selected watermarkdarkness: {download_option.watermark_darkness}</small>

                                                        </div>
                                                    </section>
                                                </>
                                            }
                                            <section className="row mt-2">
                                                <div className="col-md-12">
                                                    <div className="cust-tab-sem py-3 cust-option-select-tab">
                                                        <ul className='flex-wrap'>
                                                            <li>
                                                                <div className='cust-checkbox-square' >
                                                                    <input type='checkbox' id="question" name='question' defaultChecked={download_option.question} onChange={(e) => { change_option(e.target.checked, 'question') }} />
                                                                    <label htmlFor='question'></label>
                                                                    <bdi>Question</bdi>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='cust-checkbox-square' >
                                                                    <input type='checkbox' id="answer" name='answer' defaultChecked={download_option.answer} onChange={(e) => { change_option(e.target.checked, 'answer') }} />
                                                                    <label htmlFor='answer'></label>
                                                                    Answer key
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='cust-checkbox-square' >
                                                                    <input type='checkbox' id="solution" name='solution' defaultChecked={download_option.solution} onChange={(e) => { change_option(e.target.checked, 'solution') }} />
                                                                    <label htmlFor='solution'></label>
                                                                    Solution
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='cust-checkbox-square' >
                                                                    <input type='checkbox' id="twocolumn" name='twocolumn' defaultChecked={download_option.twocolumn} onChange={(e) => { change_option(e.target.checked, 'twocolumn') }} />
                                                                    <label htmlFor='twocolumn'></label>
                                                                    2 column
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='cust-checkbox-square' >
                                                                    <input type='checkbox' id="pyq" name='pyq' defaultChecked={download_option.pyq} onChange={(e) => { change_option(e.target.checked, 'pyq') }} />
                                                                    <label htmlFor='pyq'></label>
                                                                    PYQ Label
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='cust-checkbox-square' >
                                                                    <input type='checkbox' id="print_type" name='print_type' defaultChecked={download_option.print_type} onChange={(e) => { change_option(e.target.checked, 'print_type') }} />
                                                                    <label htmlFor='print_type'></label>
                                                                    Black and White
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {!(download_option.solution || download_option.question || download_option.solution) &&
                                                    <div className='col-md-12'>
                                                        <span className='text-danger'>Please Selected at lest one option</span>
                                                    </div>
                                                }
                                            </section>
                                            <section className="row mt-2">
                                                <div className='col-md-12'>
                                                    <label className='cust-label'>Organization Name</label>
                                                    <input required type='text' className='form-control cust-form-control' defaultValue={download_option.organization_text} name='organization_text' onChange={(e) => { change_option(e.target.value, 'organization_text') }} />
                                                </div>
                                            </section>
                                            <section className="row mt-2">
                                                <div className='col-md-12'>
                                                    <label className='cust-label'>Exam Name</label>
                                                    <input required type='text' className='form-control cust-form-control' defaultValue={download_option.exam_name} name='exam_name' onChange={(e) => { change_option(e.target.value, 'exam_name') }} />
                                                    {(download_option.exam_name.trim('') == '') &&
                                                        <div className='col-md-12'>
                                                            <span className='text-danger'>Please Enter Exam Name</span>
                                                        </div>
                                                    }
                                                </div>
                                            </section>
                                            <section className="row mt-2">
                                                <div className='col-6'>
                                                    <label className='cust-label'>Exam Date</label>
                                                    <input required type='date' className='form-control cust-form-control' defaultValue={download_option.exam_date} name='exam_date' onChange={(e) => { change_option(e.target.value, 'exam_date') }} />
                                                </div>
                                                <div className='col-6'>
                                                    <label className='cust-label'>Time</label>
                                                    <input required type='number' min={0} className='form-control cust-form-control' defaultValue={download_option.exam_time} name='exam_time' onChange={(e) => { change_option(e.target.value, 'exam_time') }} />
                                                    <small>(Enter the time value in minutes format.)</small>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div className="row my-2 sticky-bottom generate-pdf">
                                        <div className="col-md-12">
                                            <button
                                                className='btn btn-comn-1 export-btn w-100 text-center'
                                                disabled={(download_loader || !(download_option.solution || download_option.question || download_option.solution)) || (download_option.exam_name.trim('') == '')}  // Pass the boolean condition directly
                                                onClick={() => Generatepdftohtml()}
                                            >
                                                {download_loader ? (
                                                    pdf_loader ? (
                                                        <>
                                                            {`Please wait, downloading PDF ${pdf_loader_text}`}
                                                        </>
                                                    ) : (
                                                        <Spinner animation="border" role="status" className='cust-spin'>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    )
                                                ) : (
                                                    'Download'
                                                )}
                                            </button>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>

            {/* Question drawer */}
            <Drawer.Root
                shouldScaleBackground
                open={selected_questions_drawer}
                // dismissible
                // modal
                onClose={() => set_selected_questions_drawer(false)}
            >
                <Drawer.Portal>
                    <div onClick={() => set_selected_questions_drawer(false)}>
                        <Drawer.Overlay className='cust-overlay-drawer' />
                    </div>
                    <Drawer.Content>
                        <div className='drawer-inner pt-2'>
                            <div className='row'>
                                <div className='col-md-12 d-flex justify-content-center'>
                                    <div className='border-top-notch'>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='row scrollable-drawer solutions-drw'>
                                        <div className='col-md-12 mb-3'>
                                            <div style={{ height: '100%' }}>
                                                {renderedHtml}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>

            {select_data.length > 0 && <Pdfgenerator data={select_data} convertpdf={Generatepdf} download_option={download_option} />}
        </>
    );
}

export default Downloadpdf;
